/* eslint-disable @typescript-eslint/no-unused-vars */
//@ts-nocheck
import {
  Bank,
  BookSide,
  Group,
  MangoAccount,
  MangoClient,
  ParsedFillEvent,
  PerpOrder,
  PerpPosition,
  Serum3Market,
} from '@blockworks-foundation/mango-v4'
import { PerpMarket } from '@blockworks-foundation/mango-v4/'
import {
  CONNECTION_COMMITMENT,
  DEFAULT_PRIORITY_FEE,
  TRITON_DEDICATED_URL,
} from '@components/settings/RpcSettings'
import { BN, web3 } from '@coral-xyz/anchor'
import { Orderbook } from '@project-serum/serum'
import { OpenOrders, Order } from '@project-serum/serum/lib/market'
import {
  IExecutionLineAdapter,
  IOrderLineAdapter,
} from '@public/charting_library-mango/charting_library'
import {
  Connection,
  Keypair,
  // Keypair,
  PublicKey,
  TransactionInstruction,
} from '@solana/web3.js'
import produce from 'immer'
import {
  ActivityFeed,
  MangoTokenStatsItem,
  NFT,
  OrderbookL2,
  OrderbookTooltip,
  PerpStatsItem,
  PerpTradeHistory,
  PositionStat,
  SerumEvent,
  SpotBalances,
  SpotTradeHistory,
  SwapHistoryItem,
  SwapTypes,
  ThemeData,
  TokenStatsItem,
  TourSettings,
  TradeForm,
} from 'types/index'
import { nftThemeMeta } from 'utils/theme'
import EmptyWallet from 'utils/wallet'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { Token } from '../types/jupiter'
import { PAGINATION_PAGE_LENGTH } from '../utils/constants'
import { TransactionNotification } from '../utils/notifications'
import { TokenAccount } from '../utils/tokens'
import perpPositionsUpdater from './perpPositionsUpdater'
import spotBalancesUpdater from './spotBalancesUpdater'

export enum OrderTypes {
  LIMIT = 'Limit',
  MARKET = 'Market',
}

const ENDPOINTS = [
  {
    name: 'mainnet-beta',
    url: process.env.NEXT_PUBLIC_ENDPOINT || TRITON_DEDICATED_URL,
    websocket: process.env.NEXT_PUBLIC_ENDPOINT || TRITON_DEDICATED_URL,
    custom: false,
  },
  {
    name: 'devnet',
    url: 'https://realms-develope-935c.devnet.rpcpool.com/67f608dc-a353-4191-9c34-293a5061b536',
    websocket:
      'https://realms-develope-935c.devnet.rpcpool.com/67f608dc-a353-4191-9c34-293a5061b536',
    custom: false,
  },
]

export const CLUSTER: 'mainnet-beta' | 'devnet' = 'mainnet-beta'

const ENDPOINT = ENDPOINTS.find((e) => e.name === CLUSTER) || ENDPOINTS[0]

export const emptyWallet = new EmptyWallet(Keypair.generate())

export const DEFAULT_TRADE_FORM: TradeForm = {
  side: 'buy',
  price: undefined,
  baseSize: '',
  quoteSize: '',
  tradeType: OrderTypes.LIMIT,
  triggerPrice: '',
  postOnly: false,
  ioc: false,
  reduceOnly: false,
}

export type MangoStore = {
  activityFeed: {
    feed: Array<ActivityFeed>
    loading: boolean
    queryParams: string
  }
  connected: boolean
  connection: Connection
  group: Group | undefined
  groupLoaded: boolean
  client: MangoClient
  showUserSetup: boolean
  mangoAccount: {
    current: MangoAccount | undefined
    initialLoad: boolean
    lastSlot: number
    openOrderAccounts: OpenOrders[]
    openOrders: Record<string, Order[] | PerpOrder[]>
    perpPositions: PerpPosition[]
    spotBalances: SpotBalances
    swapHistory: {
      data: SwapHistoryItem[]
      initialLoad: boolean
      loading: boolean
    }
    tradeHistory: {
      data: Array<SpotTradeHistory | PerpTradeHistory>
      loading: boolean
    }
  }
  mangoAccounts: MangoAccount[]
  markets: Serum3Market[] | undefined
  transactionNotificationIdCounter: number
  transactionNotifications: Array<TransactionNotification>
  perpMarkets: PerpMarket[]
  perpStats: {
    loading: boolean
    data: PerpStatsItem[] | null
    positions: {
      initialLoad: boolean
      loading: boolean
      largest: PositionStat[]
      closestToLiq: PositionStat[]
    }
  }
  orderbookTooltip: OrderbookTooltip | undefined
  prependedGlobalAdditionalInstructions: TransactionInstruction[]
  priorityFee: number
  selectedMarket: {
    name: string | undefined
    current: Serum3Market | PerpMarket | undefined
    fills: (ParsedFillEvent | SerumEvent)[]
    bidsAccount: BookSide | Orderbook | undefined
    asksAccount: BookSide | Orderbook | undefined
    orderbook: OrderbookL2
    markPrice: number
    lastSeenSlot: {
      bids: number
      asks: number
    }
  }
  serumMarkets: Serum3Market[]
  serumOrders: Order[] | undefined
  settings: {
    loading: boolean
    tours: TourSettings
    uiLocked: boolean
  }
  successAnimation: {
    swap: boolean
    theme: boolean
    trade: boolean
  }
  swap: {
    inputBank: Bank | undefined
    outputBank: Bank | undefined
    inputTokenInfo: Token | undefined
    outputTokenInfo: Token | undefined
    margin: boolean
    slippage: number
    swapMode: 'ExactIn' | 'ExactOut'
    amountIn: string
    amountOut: string
    flipPrices: boolean
    swapOrTrigger: SwapTypes
    triggerPrice: string
  }
  set: (x: (x: MangoStore) => void, actionName: string) => void
  themeData: ThemeData
  tokenStats: {
    initialLoad: boolean
    loading: boolean
    data: TokenStatsItem[] | null
    mangoStats: MangoTokenStatsItem[]
  }
  tradeForm: TradeForm
  tradingView: {
    orderLines: Map<string | BN, IOrderLineAdapter>
    tradeExecutions: Map<string, IExecutionLineAdapter>
  }
  wallet: {
    tokens: TokenAccount[]
    nfts: {
      data: NFT[] | []
      initialLoad: boolean
      loading: boolean
    }
  }
  window: {
    width: number
    height: number
  }
  actions: {
    fetchActivityFeed: (
      mangoAccountPk: string,
      offset?: number,
      params?: string,
      limit?: number,
    ) => Promise<void>
    fetchGroup: () => Promise<void>
    reloadMangoAccount: (slot?: number) => Promise<void>
    fetchMangoAccounts: (ownerPk: PublicKey) => Promise<void>
    fetchNfts: (connection: Connection, walletPk: PublicKey) => void
    fetchOpenOrders: (refetchMangoAccount?: boolean) => Promise<void>
    fetchPerpStats: () => void
    fetchPositionsStats: () => void
    fetchSwapHistory: (
      mangoAccountPk: string,
      timeout?: number,
      offset?: number,
      limit?: number,
    ) => Promise<void>
    fetchTokenStats: () => void
    fetchTourSettings: (walletPk: string) => void
    fetchWalletTokens: (walletPk: PublicKey) => Promise<void>
    // connectMangoClientWithWallet: (wallet: WalletAdapter) => Promise<void>
    loadMarketFills: () => Promise<void>
    updateConnection: (url: string) => void
    setPrependedGlobalAdditionalInstructions: (
      instructions: TransactionInstruction[],
    ) => void
    estimatePriorityFee: (feeMultiplier: number) => Promise<void>
  }
}

const mangoStore = create<MangoStore>()(
  subscribeWithSelector((_set, _get) => {
    // let rpcUrl = ENDPOINT.url
    // let swapMargin = true

    // if (typeof window !== 'undefined' && CLUSTER === 'mainnet-beta') {
    //   const urlFromLocalStorage = localStorage.getItem(RPC_PROVIDER_KEY)
    //   const swapMarginFromLocalStorage = localStorage.getItem(SWAP_MARGIN_KEY)
    //   rpcUrl = urlFromLocalStorage
    //     ? JSON.parse(urlFromLocalStorage)
    //     : ENDPOINT.url
    //   swapMargin = swapMarginFromLocalStorage
    //     ? JSON.parse(swapMarginFromLocalStorage)
    //     : true
    // }

    // let connection: Connection
    // try {
    //   // if connection is using Triton RpcPool then use whirligig
    //   // https://docs.triton.one/project-yellowstone/whirligig-websockets
    //   if (rpcUrl.includes('rpcpool')) {
    //     connection = new web3.Connection(rpcUrl, {
    //       wsEndpoint: `${rpcUrl.replace('http', 'ws')}/whirligig`,
    //       commitment: CONNECTION_COMMITMENT,
    //     })
    //   } else {
    //     connection = new web3.Connection(rpcUrl, CONNECTION_COMMITMENT)
    //   }
    // } catch {
    //   connection = new web3.Connection(ENDPOINT.url, CONNECTION_COMMITMENT)
    // }
    // const provider = new AnchorProvider(connection, emptyWallet, options)
    // provider.opts.skipPreflight = true
    // const client = initMangoClient(provider)

    return {
      activityFeed: {
        feed: [],
        loading: true,
        queryParams: '',
      },
      connected: false,
      connection: new web3.Connection(ENDPOINT.url, CONNECTION_COMMITMENT),
      group: undefined,
      groupLoaded: false,
      // client,
      showUserSetup: false,
      mangoAccount: {
        current: undefined,
        initialLoad: true,
        lastSlot: 0,
        openOrderAccounts: [],
        openOrders: {},
        perpPositions: [],
        spotBalances: {},
        swapHistory: { data: [], loading: true, initialLoad: true },
        tradeHistory: { data: [], loading: true },
      },
      mangoAccounts: [],
      markets: undefined,
      transactionNotificationIdCounter: 0,
      transactionNotifications: [],
      perpMarkets: [],
      perpStats: {
        loading: false,
        data: [],
        positions: {
          initialLoad: true,
          loading: true,
          largest: [],
          closestToLiq: [],
        },
      },
      orderbookTooltip: undefined,
      priorityFee: DEFAULT_PRIORITY_FEE,
      prependedGlobalAdditionalInstructions: [],
      selectedMarket: {
        name: 'SOL-PERP',
        current: undefined,
        fills: [],
        bidsAccount: undefined,
        asksAccount: undefined,
        lastSeenSlot: {
          bids: 0,
          asks: 0,
        },
        orderbook: {
          bids: [],
          asks: [],
        },
        markPrice: 0,
      },
      serumMarkets: [],
      serumOrders: undefined,
      set: (fn, actionName) =>
        _set(produce(fn), false, `mango/set/${actionName}`),
      settings: {
        loading: false,
        tours: {
          account_tour_seen: true,
          swap_tour_seen: true,
          trade_tour_seen: true,
          wallet_pk: '',
        },
        uiLocked: true,
      },
      successAnimation: {
        swap: false,
        theme: false,
        trade: false,
      },
      swap: {
        inputBank: undefined,
        outputBank: undefined,
        inputTokenInfo: undefined,
        outputTokenInfo: undefined,
        margin: true,
        slippage: 0.5,
        swapMode: 'ExactIn',
        amountIn: '',
        amountOut: '',
        flipPrices: false,
        swapOrTrigger: 'swap',
        triggerPrice: '',
      },
      themeData: nftThemeMeta.default,
      tokenStats: {
        initialLoad: false,
        loading: true,
        data: [],
        mangoStats: [],
      },
      tradeForm: DEFAULT_TRADE_FORM,
      tradingView: {
        orderLines: new Map(),
        tradeExecutions: new Map(),
      },
      wallet: {
        tokens: [],
        nfts: {
          data: [],
          loading: false,
          initialLoad: true,
        },
      },
      window: {
        width: 0,
        height: 0,
      },
      actions: {
        fetchActivityFeed: async (
          mangoAccountPk: string,
          offset = 0,
          params = '',
          limit = PAGINATION_PAGE_LENGTH,
        ) => {},
        fetchGroup: async () => {},
        reloadMangoAccount: async (confirmationSlot) => {},
        fetchMangoAccounts: async (ownerPk: PublicKey) => {},
        fetchNfts: async (connection: Connection, ownerPk: PublicKey) => {},
        fetchOpenOrders: async (refetchMangoAccount = false) => {},
        fetchPerpStats: async () => {},
        fetchPositionsStats: async () => {},
        fetchSwapHistory: async (
          mangoAccountPk: string,
          timeout = 0,
          offset = 0,
          limit = PAGINATION_PAGE_LENGTH,
        ) => {},
        fetchTokenStats: async () => {},
        fetchWalletTokens: async (walletPk: PublicKey) => {},
        // connectMangoClientWithWallet: async (wallet: WalletAdapter) => {
        //   const set = get().set
        //   try {
        //     const provider = new AnchorProvider(
        //       connection,
        //       wallet.adapter as unknown as Wallet,
        //       options,
        //     )
        //     provider.opts.skipPreflight = true
        //     const priorityFee = get().priorityFee ?? DEFAULT_PRIORITY_FEE

        //     const client = initMangoClient(provider, {
        //       prioritizationFee: priorityFee,
        //       prependedGlobalAdditionalInstructions:
        //         get().prependedGlobalAdditionalInstructions,
        //     })

        //     set((s) => {
        //       s.client = client
        //     })
        //   } catch (e) {
        //     if (e instanceof Error && e.name.includes('WalletLoadError')) {
        //       notify({
        //         title: `${wallet.adapter.name} Error`,
        //         type: 'error',
        //         description: `Please install ${wallet.adapter.name} and then reload this page.`,
        //       })
        //     }
        //   }
        // },
        async setPrependedGlobalAdditionalInstructions(
          instructions: TransactionInstruction[],
        ) {},
        async fetchTourSettings(walletPk: string) {},
        async loadMarketFills() {},
        estimatePriorityFee: async (feeMultiplier) => {},
      },
    }
  }),
)

mangoStore.subscribe((state) => state.mangoAccount.current, spotBalancesUpdater)
mangoStore.subscribe(
  (state) => state.mangoAccount.openOrderAccounts,
  spotBalancesUpdater,
)
mangoStore.subscribe(
  (state) => state.mangoAccount.current,
  perpPositionsUpdater,
)

export default mangoStore
