type Props = {
  href: string
  children: React.ReactNode
  className?: string
  newTab?: boolean
}

function ExternalLink({ href, children, className, newTab = true }: Props) {
  const props = {
    href,
    className: className,
    ...(newTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {}),
  }
  return <a {...props}>{children}</a>
}

export default ExternalLink
