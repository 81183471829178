import clsx from 'clsx'

type Values = string | number

interface ButtonGroupProps<T extends Values> {
  activeValue: T
  className?: string
  disabled?: boolean
  disabledValues?: T[]
  onChange: (x: T) => void
  unit?: string
  values: T[]
  names?: Array<string>
  large?: boolean
  variant?: 'primary' | 'secondary'
  buttonClassName?: string
}

const ButtonGroup = <T extends Values>({
  activeValue,
  // className,
  disabled,
  disabledValues,
  unit,
  values,
  onChange,
  names,
  large,
  variant = 'primary',
  buttonClassName,
}: ButtonGroupProps<T>) => {
  return (
    <div
      className={clsx(
        variant === 'primary'
          ? 'rounded-md border border-th-input-border bg-th-bkg-1'
          : '',
        disabled ? 'opacity-50' : '',
      )}
    >
      <div className="relative flex">
        {activeValue && values.includes(activeValue) ? (
          <div
            className={clsx(
              'absolute left-0 top-0 h-full transform bg-th-bkg-3',
              variant === 'primary' && 'rounded-md',
            )}
            style={{
              transform: `translateX(${
                values.findIndex((v) => v === activeValue) * 100
              }%)`,
              width: `${100 / values.length}%`,
            }}
          />
        ) : null}
        {values.map((v, i) => (
          <button
            className={clsx(
              'relative w-1/2 cursor-pointer px-3 text-center font-semibold uppercase leading-normal focus-visible:bg-th-bkg-4 focus-visible:text-th-fgd-2 disabled:cursor-not-allowed',
              variant === 'primary' && 'rounded-[5px]',
              large ? 'h-12 text-sm' : 'h-[32px] text-sm',
              v === activeValue
                ? 'bg-th-bkg-3 text-th-button'
                : 'text-th-fgd-3 md:hover:text-th-button-hover',
              disabledValues?.includes(v) && '!text-th-bkg-4',
              buttonClassName,
            )}
            disabled={disabledValues?.includes(v) || disabled}
            key={`${v}${i}`}
            onClick={() => onChange(v)}
            style={{
              width: `${100 / values.length}%`,
            }}
            type="button"
          >
            {names ? (unit ? names[i] + unit : names[i]) : unit ? v + unit : v}

            {/* to be removed later. Added just for trigger order */}
            {disabledValues?.includes(v) && (
              <span className="mx-auto block w-fit whitespace-nowrap rounded-[3px] bg-th-bkg-3 px-1 py-0.5 text-center text-[8px] font-normal lowercase leading-3 text-th-fgd-1">
                Coming Soon
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  )
}

export default ButtonGroup
