import Tooltip from '@components/shared/Tooltip'
import Image from 'next/image'
import { useState } from 'react'
import { copyToClipboard } from 'utils/index'

export function ToastifyDebug({ children }: { children: string }) {
  const [open, setOpen] = useState(false)
  return (
    <div className="Toastify-debug">
      <div
        className="Toastify-debug-button flex items-center gap-2 text-xs text-th-fgd-3"
        onClick={() => setOpen((old) => !old)}
      >
        {open ? 'Hide error' : 'Show error'}{' '}
        <Tooltip content="Copy Error">
          <Image
            className="cursor-pointer"
            alt="copy to clipboard"
            src="/icons/copy-icon.svg"
            height={16}
            onClick={(e) => {
              e.stopPropagation()
              copyToClipboard(children)
            }}
            width={16}
          />
        </Tooltip>
      </div>
      {open && <div className="Toastify-debug-content">{children}</div>}
    </div>
  )
}
