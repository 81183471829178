export default class EmptyWallet {
  constructor(readonly payer: any) {}

  async signTransaction<T extends any>(tx: T): Promise<T> {
    return tx
  }
  async signAllTransactions<T extends any>(txs: T[]): Promise<T[]> {
    return txs
  }
}
